import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage2Tellimus from "./pages/Webpage2Tellimus";
import Webpage4Privaatsuspoliitika from "./pages/Webpage4Privaatsuspoliitika";
import Webpage5K__psised from "./pages/Webpage5K__psised";
import Webpage6Toode from "./pages/Webpage6Toode";

var baseURL = 'https://almaregrill.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZmU1YmJmYjM3MGRjOTEyZDM4MzAyNTY0Y2EyOTE4YzNjMTdmZGRmY2FiMjJmZTk3MTQ1NDQyZTliNWZiN2Y5YjhhZGVhMjdlMDA4ZTQ5NWMiLCJpYXQiOjE3MjY2Mzc0OTUuNjA5NDY2LCJuYmYiOjE3MjY2Mzc0OTUuNjA5NDcsImV4cCI6MTcyNjcyMzg5NS42MDY2OTgsInN1YiI6IjY4Iiwic2NvcGVzIjpbXX0.mcwafkipwvY1_oH-076jtTIV7krireJJEAMJ99RLQrWiZCbEYiiQwXPziA_wef941JrA0xPrCcwbl_zXx-eRMnzbnA6bv8WrPENI7hlsH1-UUIHIZ0TS0nkUUKowhq4JcyE4TFWjvG8p_TzhnsHxQ57x949Jcc-Hh4GUC8gSUJ_z0aOb5boEftmqZ86zoTuIRrz18l1D42Unc1D-U_o1XNqzwkL3163AwL2KJxUs3IkFOhKIMQF2-FX64uwBz1wYDjOABXd3Jo9D-4APhI2DIArmg0Jj8-gHiajpZuK_smPsDRnMV1c-HRzJBvx6b5KVBESgybya8QF5NZMGOWINTNyzaVcnsdRy-ML6m-eMmMqRXdCMh9h-IVcDbRRtCoSAjNJTlRRVUTBbzscKgkJBoNqklgfmsT7jFGkl7Fk-lwRiSU0dcwgs_FNmzLdrt4yeMaE-gthfYL6qaKAANRpi1fjvOYSVnbyzNBdHVJnzT6ubNnQV6LfQ31XIPRbt5-cDdJZR5P1JgC-Oov9-QOgggzV9b7799_UCwE_tYWd3LcDNl5gU-ahBZrmUyqAvg0dr2k06H6a_uL4NkXX2BW4Z5mtAfLiMinpIkBdFF_nDmtnjIxtnGrcXgUGEiBciViPHwGnGQdn_OPDRcwOEWzz6OQ0cIT_7LwiNbwmDfudfTNU',
    'Access-api-key': "eyJpdiI6IlhMT3Y1WjFQWWk4cUdxdnV0K3M1THc9PSIsInZhbHVlIjoiVk9EaEp4R1pUYzBMcTYzQUhWRFdqdz09IiwibWFjIjoiN2YwYmFmZTQ1MzllNDMxNDMzNjJiMjIyYzdlZjcxNzc0ZjU5ZGVhYmE0NTdmZDg5MWE1NDA5NGUyZjI1ZTY5NyIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ilp5Rk13SGlYc3lMbHVlZW9yc3d4S1E9PSIsInZhbHVlIjoiSXhxZ2U1MUtFbGJyYlhkaG5IcWttZz09IiwibWFjIjoiZmQxZWFmNTkwZGM3NmEwNjQxOWI5NjIwZjE5YWNiNDNiOTM1ZmRjZWJiODI1NWY5YmZmOGZkODM4NTE1ZTRiYyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage2Tellimus />} exact />;
                                                                    <Route path="/et/privacyPolicy" element={<Webpage4Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage5K__psised />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage6Toode />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage1Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}